import React from 'react';
import { Route } from 'react-router-dom';

import LayoutWithoutSidebar from '../components/LayoutWithoutSidebar';

const AppSpecialRoute = ({
    component: Component,
    ...rest
  }) => (
    <Route
      {...rest}
      render={props => {
        return (
          <LayoutWithoutSidebar>
            <Component {...props} />
          </LayoutWithoutSidebar>
        );
      }}
    />
  );
  
  export default AppSpecialRoute;
