import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { OverlayTrigger, Table } from 'react-bootstrap';

import renderTooltipInbound from '../../../../components/tables/TooltipInbound';
import renderTooltipMissed from '../../../../components/tables/TooltipMissed';
import renderTooltipOutgoing from '../../../../components/tables/TooltipOutgoing';
import {
  TableInboundBadge,
  TableMissedBadge,
  TableOutgoingBadge,
} from '../../../../styles/Table.styles';
import generateKey from '../../../../helpers/uniqueKeys';

const CallListTable = ({ rows }) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="text.state" defaultMessage="Status" />
          </th>
          <th>
            <FormattedMessage
              id="text.time-of-call"
              defaultMessage="Anrufzeitpunkt"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.call-duration"
              defaultMessage="Dauer (hh:mm:ss)"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.telephone-number-name"
              defaultMessage="Rufnummer/Name"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => {
          return (
            <tr key={generateKey(row.caller)}>
              <td>
                {row.state === 'inbound' && (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltipInbound}
                  >
                    <TableInboundBadge />
                  </OverlayTrigger>
                )}
                {row.state === 'missed' && (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltipMissed}
                  >
                    <TableMissedBadge />
                  </OverlayTrigger>
                )}
                {row.state === 'outgoing' && (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltipOutgoing}
                  >
                    <TableOutgoingBadge />
                  </OverlayTrigger>
                )}
              </td>
              <td>{row.timeOfCall}</td>
              <td>{row.duration}</td>
              <td>{row.caller}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

CallListTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      state: PropTypes.string.isRequired,
      timeOfCall: PropTypes.string.isRequired,
      duration: PropTypes.string.isRequired,
      caller: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CallListTable;
