import styled from 'styled-components';

export const BackToTopButton = styled.div`
  position: fixed;
  right: 15px;
  bottom: -80px;
  transition: bottom 0.7s;
  z-index: 123456;
  &.button-visible {
    bottom: 12px;
  }
`;

export const BackToTopLink = styled.a`
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  color: #0090d0;
  background: #fff;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  &:before {
    content: '';
    font-family: o2icons;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: #0090d0;
  }
  &:hover {
    color: #005896;
  }
`;
