import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Card } from 'react-bootstrap';
import BackToOverview from '../../../components/buttons/BackToOverview';
import { usbUserData } from './data/data';
import UserManagementTable from './data/UserManagementTable';

const TabUserManagement = () => {
  return (
    <>
      <div className="tab-panel">
        <h4 style={{ marginBottom: 20 }}>
          <FormattedMessage
            id="section.lan.page.usb.tab.user-management.title"
            defaultMessage="Nutzerverwaltung für USB Massenspeicher"
          />
        </h4>
        <p>
          <FormattedMessage
            id="section.lan.page.usb.tab.user-management.content.copy"
            defaultMessage="Es sind keine Benutzerkonten erstellt."
          />
        </p>
        <UserManagementTable rows={usbUserData} />
        <div className="d-flex justify-content-start">
          <Button variant="primary">
            <FormattedMessage
              id="button.new-ubs-user.label"
              defaultMessage="Nutzer hinzufügen"
            />
          </Button>
        </div>
      </div>
      <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
        <BackToOverview linkTarget="/lan/overview" />
        <div className="d-flex flex-row justify-content-end footer-buttons">
          <Button variant="primary">
            <FormattedMessage
              id="button.save.label"
              defaultMessage="Speichern"
            />
          </Button>
        </div>
      </Card.Footer>
    </>
  );
};

export default TabUserManagement;
