/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Toast,
} from 'react-bootstrap';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import ToggleSwitch from '../../../../components/ToggleSwitch';
import DividerLine from '../../../../components/layout/DividerLine';
import BackToOverview from '../../../../components/buttons/BackToOverview';
import validationSchema from './FormAnalogueDevice.validationSchema';
import { Error } from '../../../../styles/Form.styles';
import AnalogueDevicesTable from '../data/AnalogueDevicesTable';
import {
  analogueDevicesData as analogueDevicesInitialData,
  analogueDevicesEmptyData,
} from '../data/data';

export default function PhoneConfigAnalogueDeviceForm() {
  const [showMainErrMsg, setShowMainErrMsg] = useState(false);
  const [analogueDevicesData, setAnalogueDevicesData] = useState([
    ...analogueDevicesInitialData,
  ]);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const [saveMsgActive2, setSaveMsgActive2] = useState(false);
  const [currentFormEntry, setCurrentFormEntry] = useState(
    analogueDevicesEmptyData
  );
  const [showNewButton, setShowNewButton] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [successfulModal, setSuccessfulModal] = useState(false);
  const successfulModalRef = useRef(false);
  const [currentKeyForDeletion, setCurrentKeyForDeletion] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    // change value only if user confirmed in modal dialog
    if (successfulModalRef.current) {
      successfulModalRef.current = false;
      setSuccessfulModal(false);
      const analogueDeviceNewData = analogueDevicesData.filter((item) => {
        return item.key !== currentKeyForDeletion;
      });
      setAnalogueDevicesData(analogueDeviceNewData);
      setCurrentKeyForDeletion(null);
    }
  }, [successfulModal, currentKeyForDeletion, analogueDevicesData]);

  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.activated',
      defaultMessage: 'aktiviert',
    },
    stateDeactivated: {
      id: 'toggle-switch.deactivated',
      defaultMessage: 'deaktiviert',
    },
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
    noMoreAnalogDevices: {
      id: 'messages.error.no-more-analog-devices',
      defaultMessage: 'Es sind keine weiteren analogen Geräte verfügbar.',
    },
    telephoneLine: {
      id: 'text.telephone.line',
      defaultMessage: 'Leitung',
    },
  });

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleToast2Close = () => {
    setShowModal(false);
    setSaveMsgActive2(false);
    successfulModalRef.current = true;
    setSuccessfulModal(true);
  };

  const handleRowDelete = (key) => {
    setShowMainErrMsg(false);
    setCurrentKeyForDeletion(key);
    setShowModal(true);
  };

  const handleRowSave = (key) => {
    const analogueDevicesRowEditData = analogueDevicesData.filter((item) => {
      return item.key === key;
    });
    setCurrentFormEntry(analogueDevicesRowEditData[0]);
    setShowMainErrMsg(false);
    setShowNewButton(!showNewButton);
    setShowForm(!showForm);
    setIsEdit(true);
  };

  const handleItemSave = (newItem) => {
    if (!isEdit) {
      if (newItem.internalNumber === '') {
        newItem.internalNumber = '11';
        if (newItem.phoneLine === '2') {
          newItem.internalNumber = '12';
        }
      }
      setAnalogueDevicesData((oldArray) => [...oldArray, newItem]);
    } else {
      setAnalogueDevicesData((oldArray) =>
        oldArray.map((item) => {
          if (item.key === newItem.key) {
            return newItem;
          }
          return item;
        })
      );
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={currentFormEntry}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        setCurrentFormEntry(values);
        setSaveMsgActive(true);
        resetForm({});
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="tab-panel">
            <h4 style={{ marginBottom: 20 }}>
              <FormattedMessage
                id="section.phone.page.config.tab.analog-devices.content.headline"
                defaultMessage="Angeschlossene Analog-Geräte"
              />
            </h4>
            <AnalogueDevicesTable
              rows={analogueDevicesData}
              onDelete={handleRowDelete}
              onSave={handleRowSave}
            />
            {showForm && (
              <>
                <DividerLine />
                <Form.Group as={Row} controlId="formRuleActive">
                  <Form.Label column lg={4} xl={3}>
                    <FormattedMessage
                      id="text.call-waitung"
                      defaultMessage="Anklopfen"
                    />
                  </Form.Label>
                  <Col lg={8} xl={9}>
                    <ToggleSwitch
                      id="toggleSwitchRule"
                      checked={values.callWaiting}
                      onChange={(checked) => {
                        setFieldValue('callWaiting', checked);
                      }}
                      small
                    />
                    <span
                      className={
                        values.callWaiting
                          ? 'o2-badge o2-badge-green'
                          : 'o2-badge o2-badge-grey'
                      }
                    >
                      <FormattedMessage
                        id="section.phone.page.config.tab.dect.dect-device-call-waiting"
                        defaultMessage="Anklopfen ist"
                      />{' '}
                      {values.callWaiting
                        ? intl.formatMessage(messages.stateActivated)
                        : intl.formatMessage(messages.stateDeactivated)}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formDeviceName">
                  <Form.Label column lg={4} xl={3}>
                    <FormattedMessage
                      id="text.device-name"
                      defaultMessage="Gerätename"
                    />
                  </Form.Label>
                  <Col lg={8} xl={9}>
                    <Form.Control
                      type="text"
                      name="deviceName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.deviceName}
                      className={
                        touched.deviceName && errors.deviceName ? 'error' : null
                      }
                    />
                    {touched.deviceName && errors.deviceName ? (
                      <Error>
                        <FormattedMessage id={errors.deviceName} />
                      </Error>
                    ) : null}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formPhoneLine">
                  <Form.Label column lg={4} xl={3}>
                    <FormattedMessage
                      id="text.telephone.line"
                      defaultMessage="Leitung"
                    />
                  </Form.Label>
                  <Col lg={8} xl={9}>
                    <Form.Control
                      custom
                      as="select"
                      name="phoneLine"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneLine}
                      disabled={isEdit}
                    >
                      {isEdit && values.phoneLine === '1' && (
                        <option value="1">
                          {intl.formatMessage(messages.telephoneLine)} 1
                        </option>
                      )}
                      {isEdit && values.phoneLine === '2' && (
                        <option value="2">
                          {intl.formatMessage(messages.telephoneLine)} 2
                        </option>
                      )}
                      {analogueDevicesData.length === 0 && (
                        <option value="1">
                          {intl.formatMessage(messages.telephoneLine)} 1
                        </option>
                      )}
                      {analogueDevicesData.length === 1 &&
                        analogueDevicesData[0].phoneLine === '1' && (
                          <>
                            <option value="2">
                              {intl.formatMessage(messages.telephoneLine)} 2
                            </option>
                          </>
                        )}
                      {analogueDevicesData.length === 1 &&
                        analogueDevicesData[0].phoneLine === '2' && (
                          <>
                            <option value="1">
                              {intl.formatMessage(messages.telephoneLine)} 1
                            </option>
                          </>
                        )}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </>
            )}
            <Toast
              onClose={() => {
                setSaveMsgActive(false);
                handleItemSave(currentFormEntry);
                setShowForm(!showForm);
                setShowNewButton(!showNewButton);
                setIsEdit(false);
              }}
              show={saveMsgActive}
              delay={2500}
              autohide
              className="toast-save-msg toast-table-inbetween"
            >
              <Toast.Body>
                {intl.formatMessage(messages.changesAreAccepted)}
              </Toast.Body>
            </Toast>
            <Toast
              onClose={handleToast2Close}
              show={saveMsgActive2}
              delay={2500}
              autohide
              className="toast-save-msg toast-table-inbetween"
            >
              <Toast.Body>
                {intl.formatMessage(messages.changesAreAccepted)}
              </Toast.Body>
            </Toast>
            <div className="d-flex flex-column flex-md-row justify-content-between">
              <div />
              <div className="d-flex flex-row justify-content-end footer-buttons">
                {showForm && (
                  <>
                    <Button
                      variant="secondary"
                      className="mr-2 footer-left-button"
                      onClick={() => {
                        setShowForm(!showForm);
                        setShowNewButton(!showNewButton);
                      }}
                    >
                      <FormattedMessage
                        id="button.cancel.label"
                        defaultMessage="Abbrechen"
                      />
                    </Button>
                    <Button type="submit" variant="primary">
                      <FormattedMessage
                        id="button.save.label"
                        defaultMessage="Speichern"
                      />
                    </Button>
                  </>
                )}
              </div>
            </div>
            <Modal
              show={showModal}
              onHide={handleModalClose}
              backdrop="static"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <FormattedMessage
                    id="text.warning"
                    defaultMessage="Warnung"
                  />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormattedMessage
                  id="text.delete-individual-fixed-ipv4-address-entry"
                  defaultMessage="Möchten Sie dieses Gerät löschen?"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                  <FormattedMessage
                    id="button.cancel.label"
                    defaultMessage="Abbrechen"
                  />
                </Button>
                <Button
                  variant="inactive"
                  onClick={() => {
                    handleModalClose();
                    setSaveMsgActive2(true);
                  }}
                >
                  <FormattedMessage
                    id="button.delete.label"
                    defaultMessage="Löschen"
                  />
                </Button>
              </Modal.Footer>
            </Modal>
            {showForm && <DividerLine />}
            <Alert
              variant="danger"
              onClose={() => setShowMainErrMsg(false)}
              dismissible
              show={showMainErrMsg}
              className="alert-inbetween"
            >
              <Alert.Heading>
                <FormattedMessage
                  id="messages.error.headline"
                  defaultMessage="Ein Fehler ist aufgetreten"
                />
              </Alert.Heading>
              <p>{intl.formatMessage(messages.noMoreAnalogDevices)}</p>
            </Alert>
            {showNewButton && (
              <div className="d-flex justify-content-start">
                <Button
                  variant="primary"
                  onClick={() => {
                    // max 2 lines
                    if (analogueDevicesData.length >= 2) {
                      setShowMainErrMsg(true);
                    } else {
                      let newPhoneLine = '';
                      if (analogueDevicesData.length === 0) {
                        newPhoneLine = '1';
                      }
                      if (
                        analogueDevicesData.length === 1 &&
                        analogueDevicesData[0].phoneLine === '1'
                      ) {
                        newPhoneLine = '2';
                      }
                      if (
                        analogueDevicesData.length === 1 &&
                        analogueDevicesData[0].phoneLine === '2'
                      ) {
                        newPhoneLine = '1';
                      }
                      const newAnalogueDevicesEmptyData = {
                        ...analogueDevicesEmptyData,
                        key: uuidv4(),
                        phoneLine: newPhoneLine,
                      };
                      setCurrentFormEntry(newAnalogueDevicesEmptyData);
                      setShowNewButton(!showNewButton);
                      setShowForm(!showForm);
                    }
                  }}
                >
                  <FormattedMessage
                    id="button.add-new-analog-device.label"
                    defaultMessage="Analog-Gerät hinzufügen"
                    disabled
                  />
                </Button>
              </div>
            )}
          </div>
          <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
            <BackToOverview linkTarget="/phone/overview" />
            <div className="d-flex flex-row justify-content-end footer-buttons" />
          </Card.Footer>
        </Form>
      )}
    </Formik>
  );
}
