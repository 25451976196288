import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import generateKey from '../../../../helpers/uniqueKeys';

const PrinterTable = ({ rows }) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>
            <FormattedMessage
              id="text.printer-name"
              defaultMessage="Drucker-Name"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.queue-name"
              defaultMessage="Warteschlangenname"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => {
          return (
            <tr key={generateKey(row.printerName)}>
              <td>{row.printerName}</td>
              <td>{row.queueName}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

PrinterTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      printerName: PropTypes.string.isRequired,
      queueName: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default PrinterTable;
