export const buildFullMacAddress = (
  byte1,
  byte2,
  byte3,
  byte4,
  byte5,
  byte6
) => {
  return `${byte1}:${byte2}:${byte3}:${byte4}:${byte5}:${byte6}`;
};

export const buildFullIpV4Address = (part1, part2, part3, part4) => {
  return `${part1}.${part2}.${part3}.${part4}`;
};
