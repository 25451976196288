import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Alert, Button, Col, Form, Modal, Row, Toast } from 'react-bootstrap';
import { Formik } from 'formik';

import ToggleSwitch from '../../../../components/ToggleSwitch';
import DividerLine from '../../../../components/layout/DividerLine';
import validationSchema from './FormDectDevice.validationSchema';
import { Error } from '../../../../styles/Form.styles';
import DectDevicesTable from '../data/DectDevicesTable';
import {
  dectDevicesData as dectDevicesInitialData,
  dectDevicesEmptyData,
} from '../data/data';

export default function PhoneConfigDectDeviceForm() {
  const [registerMsgActive, setRegisterMsgActive] = useState(false);
  const [showMainErrMsg, setShowMainErrMsg] = useState(false);
  const [dectDevicesData, setDectDevicesData] = useState([
    ...dectDevicesInitialData,
  ]);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const [saveMsgActive2, setSaveMsgActive2] = useState(false);
  const [currentFormEntry, setCurrentFormEntry] = useState(
    dectDevicesEmptyData
  );
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [successfulModal, setSuccessfulModal] = useState(false);
  const successfulModalRef = useRef(false);
  const [currentKeyForDeletion, setCurrentKeyForDeletion] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    // change value only if user confirmed in modal dialog
    if (successfulModalRef.current) {
      successfulModalRef.current = false;
      setSuccessfulModal(false);
      const dectDeviceNewData = dectDevicesData.filter((item) => {
        return item.key !== currentKeyForDeletion;
      });
      setDectDevicesData(dectDeviceNewData);
      setCurrentKeyForDeletion(null);
    }
  }, [successfulModal, currentKeyForDeletion, dectDevicesData]);

  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.activated',
      defaultMessage: 'aktiviert',
    },
    stateDeactivated: {
      id: 'toggle-switch.deactivated',
      defaultMessage: 'deaktiviert',
    },
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
    registerNewDectDevice: {
      id: 'text.register-new-dect-device.note.process-is-runing',
      defaultMessage:
        'Der Anmeldevorgang Ihrer DECT-Basisstation läuft. Bitte starten Sie innerhalb 1 Minute die Registrierung an Ihrem DECT-Telefon und geben Sie die Anmelde-PIN ein. In dieser Zeit ist eine Bedienung der Oberfläche nicht möglich.',
    },
    registerNewDectDeviceError: {
      id: 'text.register-new-dect-device.note.error',
      defaultMessage:
        'Die Anmeldung des DECT-Handsets war nicht erfolgreich. Bitte prüfen Sie die PIN-Eingabe im Handset entsprechend der PIN der Basisstation und versuchen Sie es noch einmal.',
    },
  });

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleToast2Close = () => {
    setShowModal(false);
    setSaveMsgActive2(false);
    successfulModalRef.current = true;
    setSuccessfulModal(true);
  };

  const handleRowDelete = (key) => {
    setCurrentKeyForDeletion(key);
    setShowModal(true);
  };

  const handleRowSave = (key) => {
    const dectDevicesRowEditData = dectDevicesData.filter((item) => {
      return item.key === key;
    });
    setCurrentFormEntry(dectDevicesRowEditData[0]);
    setShowForm(!showForm);
    setIsEdit(true);
  };

  const handleItemSave = (newItem) => {
    if (!isEdit) {
      setDectDevicesData((oldArray) => [...oldArray, newItem]);
    } else {
      setDectDevicesData((oldArray) =>
        oldArray.map((item) => {
          if (item.key === newItem.key) {
            return newItem;
          }
          return item;
        })
      );
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={currentFormEntry}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        setCurrentFormEntry(values);
        setSaveMsgActive(true);
        resetForm({});
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <h4 style={{ marginBottom: 20 }}>
            <FormattedMessage
              id="section.phone.page.config.tab.dect.content.headline.registered-dect-phones"
              defaultMessage="Angemeldete DECT-Telefone"
            />
          </h4>
          <DectDevicesTable
            rows={dectDevicesData}
            onDelete={handleRowDelete}
            onSave={handleRowSave}
          />
          {showForm && (
            <>
              <DividerLine />
              <Form.Group as={Row} controlId="formRuleActive">
                <Form.Label column lg={4} xl={3}>
                  <FormattedMessage
                    id="text.call-waitung"
                    defaultMessage="Anklopfen"
                  />
                </Form.Label>
                <Col lg={8} xl={9}>
                  <ToggleSwitch
                    id="toggleSwitchRule"
                    checked={values.callWaiting}
                    onChange={(checked) => {
                      setFieldValue('callWaiting', checked);
                    }}
                    small
                  />
                  <span
                    className={
                      values.callWaiting
                        ? 'o2-badge o2-badge-green'
                        : 'o2-badge o2-badge-grey'
                    }
                  >
                    <FormattedMessage
                      id="section.phone.page.config.tab.dect.dect-device-call-waiting"
                      defaultMessage="Anklopfen ist"
                    />{' '}
                    {values.callWaiting
                      ? intl.formatMessage(messages.stateActivated)
                      : intl.formatMessage(messages.stateDeactivated)}
                  </span>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formDeviceName">
                <Form.Label column lg={4} xl={3}>
                  <FormattedMessage
                    id="text.device-name"
                    defaultMessage="Gerätename"
                  />
                </Form.Label>
                <Col lg={8} xl={9}>
                  <Form.Control
                    type="text"
                    name="deviceName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.deviceName}
                    className={
                      touched.deviceName && errors.deviceName ? 'error' : null
                    }
                  />
                  {touched.deviceName && errors.deviceName ? (
                    <Error>
                      <FormattedMessage id={errors.deviceName} />
                    </Error>
                  ) : null}
                </Col>
              </Form.Group>
            </>
          )}
          <Toast
            onClose={() => {
              setSaveMsgActive(false);
              handleItemSave(currentFormEntry);
              setShowForm(!showForm);
              setIsEdit(false);
            }}
            show={saveMsgActive}
            delay={2500}
            autohide
            className="toast-save-msg toast-table-inbetween"
          >
            <Toast.Body>
              {intl.formatMessage(messages.changesAreAccepted)}
            </Toast.Body>
          </Toast>
          <Toast
            onClose={handleToast2Close}
            show={saveMsgActive2}
            delay={2500}
            autohide
            className="toast-save-msg toast-table-inbetween"
          >
            <Toast.Body>
              {intl.formatMessage(messages.changesAreAccepted)}
            </Toast.Body>
          </Toast>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div />
            <div className="d-flex flex-row justify-content-end footer-buttons">
              {showForm && (
                <>
                  <Button
                    variant="secondary"
                    className="mr-2 footer-left-button"
                    onClick={() => setShowForm(!showForm)}
                  >
                    <FormattedMessage
                      id="button.cancel.label"
                      defaultMessage="Abbrechen"
                    />
                  </Button>
                  <Button type="submit" variant="primary">
                    <FormattedMessage
                      id="button.save.label"
                      defaultMessage="Speichern"
                    />
                  </Button>
                </>
              )}
            </div>
          </div>
          <Modal
            show={showModal}
            onHide={handleModalClose}
            backdrop="static"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id="text.warning" defaultMessage="Warnung" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormattedMessage
                id="text.delete-individual-fixed-ipv4-address-entry"
                defaultMessage="Möchten Sie dieses Gerät löschen?"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                <FormattedMessage
                  id="button.cancel.label"
                  defaultMessage="Abbrechen"
                />
              </Button>
              <Button
                variant="inactive"
                onClick={() => {
                  handleModalClose();
                  setSaveMsgActive2(true);
                }}
              >
                <FormattedMessage
                  id="button.delete.label"
                  defaultMessage="Löschen"
                />
              </Button>
            </Modal.Footer>
          </Modal>
          {showForm && <DividerLine />}
          <Toast
            onClose={() => {
              setRegisterMsgActive(false);
              setShowMainErrMsg(true);
            }}
            show={registerMsgActive}
            delay={7500}
            autohide
            className="toast-register-msg toast-table-inbetween"
          >
            <Toast.Body>
              {intl.formatMessage(messages.registerNewDectDevice)}
            </Toast.Body>
          </Toast>
          <Alert
            variant="danger"
            onClose={() => setShowMainErrMsg(false)}
            dismissible
            show={showMainErrMsg}
            className="alert-inbetween"
          >
            <Alert.Heading>
              <FormattedMessage
                id="messages.error.headline"
                defaultMessage="Ein Fehler ist aufgetreten"
              />
            </Alert.Heading>
            <p>{intl.formatMessage(messages.registerNewDectDeviceError)}</p>
          </Alert>
          <div className="d-flex justify-content-start">
            <Button
              variant="primary"
              onClick={() => setRegisterMsgActive(true)}
            >
              <FormattedMessage
                id="button.add-new-dect-device.label"
                defaultMessage="Mobilteil hinzufügen"
              />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
