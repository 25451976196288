import React from 'react';

import FormLogin from './forms/FormLogin';
import ScrollToTop from '../../components/ScrollToTop';

const PageLogin = () => {
  return (
    <>
      <FormLogin />
      <ScrollToTop />
    </>
  );
};

export default PageLogin;
