import React, { useEffect } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import bsCustomFileInput from 'bs-custom-file-input';

import ScrollToTop from '../../components/ScrollToTop';
import { ColMain, ContentCard } from '../../styles/Bootstrap.styles';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PageSystemSettings() {
  const intl = useIntl();

  useEffect(() => {
    bsCustomFileInput.init();
  }, []);

  const messages = defineMessages({
    fileUploadLabel: {
      id: 'messages.file-upload.no-file.label',
      defaultMessage: 'Keine Datei ausgewählt',
    },
    chooseFileLabel: {
      id: 'messages.file-upload.choose-file.label',
      defaultMessage: 'Datei auswählen',
    },
  });

  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3 style={{ marginBottom: 0 }}>
              <FormattedMessage
                id="section.system.title"
                defaultMessage="System"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.system.page.settings.title"
                defaultMessage="Einstellungen"
              />
            </h3>
            <Row>
              <Col xs={12} xl={6}>
                <ContentCard>
                  <Card.Body>
                    <h4 style={{ marginBottom: 20 }}>
                      <FormattedMessage
                        id="section.system.page.settings.save-configuration.subheading"
                        defaultMessage="Konfiguration speichern"
                      />
                    </h4>
                    <p>
                      <FormattedMessage
                        id="section.system.page.settings.save-configuration.copy"
                        defaultMessage="Hier können Sie die Konfiguration der O<sub>2</sub>{nbsp}HomeBox speichern."
                        values={formattedMessageValues}
                      />
                    </p>
                  </Card.Body>
                  <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                    <div />
                    <div className="d-flex flex-row justify-content-end footer-buttons">
                      <Button variant="primary">
                        <FormattedMessage
                          id="button.save.label"
                          defaultMessage="Speichern"
                        />
                      </Button>
                    </div>
                  </Card.Footer>
                </ContentCard>
              </Col>
              <Col xs={12} xl={6}>
                <ContentCard>
                  <Card.Body>
                    <h4 style={{ marginBottom: 20 }}>
                      <FormattedMessage
                        id="section.system.page.settings.upload-configuration.subheading"
                        defaultMessage="Konfiguration laden"
                      />
                    </h4>
                    <p>
                      <FormattedMessage
                        id="section.system.page.settings.upload-configuration.copy"
                        defaultMessage="Wählen Sie die Konfigurationsdatei aus."
                      />
                    </p>
                    <Form>
                      <Form.File
                        id="config-file-upload"
                        label={intl.formatMessage(messages.fileUploadLabel)}
                        data-browse={intl.formatMessage(
                          messages.chooseFileLabel
                        )}
                        custom
                      />
                    </Form>
                  </Card.Body>
                  <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                    <div />
                    <div className="d-flex flex-row justify-content-end footer-buttons">
                      <Button variant="primary">
                        <FormattedMessage
                          id="button.upload.label"
                          defaultMessage="Laden"
                        />
                      </Button>
                    </div>
                  </Card.Footer>
                </ContentCard>
              </Col>
            </Row>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <FormattedMessage
                    id="sub-section.help.settings.text"
                    defaultMessage="Hier haben Sie die Möglichkeit, die Einstellungen Ihrer O<sub>2</sub>{nbsp}HomeBox in einer Datei auf Ihrem Computer zu sichern. Gehen die Einstellungen aufgrund eines Defektes oder eines Zurücksetzens in den Auslieferungszustand verloren, kann mit der Sicherungsdatei der ursprüngliche Zustand wieder hergestellt werden."
                    values={formattedMessageValues}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
