import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'react-bootstrap';

const renderTooltipInbound = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip {...props} className="tooltip-inbound">
      <FormattedMessage id="text.call.inbound" defaultMessage="eingehend" />
    </Tooltip>
  );
};

export default renderTooltipInbound;
