import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

import generateKey from '../../../../helpers/uniqueKeys';

const PhonesTable = ({ rows }) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="text.name" defaultMessage="Name" />
          </th>
          <th>
            <FormattedMessage
              id="text.internal-number"
              defaultMessage="Interne Rufnummer"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.telephone-number"
              defaultMessage="Telefonnummer"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => {
          return (
            <tr key={generateKey(row.name)}>
              <td>{row.name}</td>
              <td>{row.internalNumber}</td>
              <td>{row.externalNumber}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

PhonesTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      internalNumber: PropTypes.string.isRequired,
      externalNumber: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default PhonesTable;
