import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import generateKey from '../../../helpers/uniqueKeys';
import formattedMessageValues from '../../../i18n/FormattedMessageValues';

const MediaCenterTable = ({ rows }) => {
  return (
    <div className="tab-panel">
      <h4 style={{ marginBottom: 20 }}>
        <FormattedMessage
          id="section.lan.page.overview.media-center.content.headline"
          defaultMessage="Status des O<sub>2</sub>{nbsp}HomeBox MediaCenters"
          values={formattedMessageValues}
        />
      </h4>
      <Table responsive>
        <thead>
          <tr>
            <th>
              <FormattedMessage
                id="text.media-center-option"
                defaultMessage="MediaCenter-Option"
              />
            </th>
            <th>
              <FormattedMessage id="text.status" defaultMessage="Status" />
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => {
            return (
              <tr key={generateKey(row.option)}>
                <td>{row.option}</td>
                <td>{row.state}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

MediaCenterTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      option: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default MediaCenterTable;
