import React, { useContext, useEffect } from 'react';
import { Route } from 'react-router-dom';

import { SetupAssistantContext } from '../context/hardware/SetupAssistantProvider';
import Layout from '../components/Layout';

const AppRoute = ({
    component: Component,
    ...rest
  }) => {
  const [, setAssistantFirstRun] = useContext(
    SetupAssistantContext
  );

  useEffect(() => {
    setAssistantFirstRun(false);
  }, [setAssistantFirstRun]);

  return (
    <Route
      {...rest}
      render={props => {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  )};
  
  export default AppRoute;
