import React, { useState, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Col, Form, Row, Toast } from 'react-bootstrap';
import { Formik } from 'formik';
import bsCustomFileInput from 'bs-custom-file-input';

import { TamContext } from '../../../../context/software/TamProvider';
import ToggleSwitch from '../../../../components/ToggleSwitch';
import BackToOverview from '../../../../components/buttons/BackToOverview';
// import validationSchema from './FormActivate.validationSchema';
import { Error } from '../../../../styles/Form.styles';

export default function PhoneAnswerphoneActivateForm() {
  const [tamActive, setTamActive] = useContext(TamContext);
  const [disableMessageLength, setDisableMessageLength] = useState(false);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    bsCustomFileInput.init();
  }, []);

  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.activated',
      defaultMessage: 'aktiviert',
    },
    stateDeactivated: {
      id: 'toggle-switch.deactivated',
      defaultMessage: 'deaktiviert',
    },
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
    outgoingMessageWithRecording: {
      id: 'form.answerphone.activate.outgoing-message-with-recording.option',
      defaultMessage: 'Ansage mit Aufzeichnung',
    },
    outgoingMessageWithOutRecording: {
      id: 'form.answerphone.activate.outgoing-message-without-recording.option',
      defaultMessage: 'Nur Ansage',
    },
    takeCallImmediately: {
      id: 'form.answerphone.activate.taking-calls.immediately.option',
      defaultMessage: 'Sofort annehmen',
    },
    timeDuration10secs: {
      id: 'form.common.time-duration.option.10-seconds',
      defaultMessage: '10 Sekunden',
    },
    timeDuration15secs: {
      id: 'form.common.time-duration.option.15-seconds',
      defaultMessage: '15 Sekunden',
    },
    timeDuration20secs: {
      id: 'form.common.time-duration.option.20-seconds',
      defaultMessage: '20 Sekunden',
    },
    timeDuration25secs: {
      id: 'form.common.time-duration.option.25-seconds',
      defaultMessage: '25 Sekunden',
    },
    timeDuration30secs: {
      id: 'form.common.time-duration.option.30-seconds',
      defaultMessage: '30 Sekunden',
    },
    timeDuration60secs: {
      id: 'form.common.time-duration.option.60-seconds',
      defaultMessage: '60 Sekunden',
    },
    fileUploadLabel: {
      id: 'messages.file-upload.no-file.label',
      defaultMessage: 'Keine Datei ausgewählt',
    },
    chooseFileLabel: {
      id: 'messages.file-upload.choose-file.label',
      defaultMessage: 'Datei auswählen',
    },
    outgoingMessageFileType: {
      id: 'form.answerphone.activate.choose-file.outgoing-message.file-type',
      defaultMessage: 'Wählen Sie eine Ansage aus (MP3)',
    },
  });

  const tamActiveClassNames = tamActive
    ? 'o2-badge o2-badge-green mb-4'
    : 'o2-badge o2-badge-grey mb-4';

  return (
    <Formik
      initialValues={{
        outgoingMessageMode: '1',
        takingCalls: '3',
        incomingMessageLength: '4',
        outgoingMessageFileWithRecording: '',
        outgoingMessageFileWithOutRecording: '',
      }}
      // validationSchema={validationSchema}
      onSubmit={(values) => {
        setSaveMsgActive(true);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="tab-panel">
            <h4 style={{ marginBottom: 20 }}>
              <FormattedMessage
                id="section.phone.page.answerphone.tab.activate.content.headline"
                defaultMessage="Anrufbeantworter aktivieren"
              />
            </h4>
            <ToggleSwitch
              style={{ marginBottom: '30px' }}
              name="usingTam"
              id="toggleSwitchUsingTam"
              checked={tamActive}
              onChange={(checked) => {
                setTamActive(checked);
              }}
            />
            <span className={tamActiveClassNames}>
              <FormattedMessage
                id="section.phone.answerphone.config.tab.activate.answerphone-function"
                defaultMessage="Der Anrufbeantworter ist"
              />{' '}
              {tamActive
                ? intl.formatMessage(messages.stateActivated)
                : intl.formatMessage(messages.stateDeactivated)}
            </span>
            <Form.Group as={Row} controlId="formOutgoingMessageMode">
              <Form.Label column lg={4} xl={3}>
                <FormattedMessage
                  id="form.answerphone.activate.outgoing-message-mode.label"
                  defaultMessage="Ansagemodus"
                />
              </Form.Label>
              <Col lg={8} xl={9}>
                <Form.Control
                  custom
                  as="select"
                  name="outgoingMessageMode"
                  onChange={(e) => {
                    if (e.target.value === '1') {
                      setDisableMessageLength(false);
                    } else {
                      setDisableMessageLength(true);
                    }
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  value={values.outgoingMessageMode}
                  disabled={!tamActive}
                >
                  <option value="1">
                    {intl.formatMessage(messages.outgoingMessageWithRecording)}
                  </option>
                  <option value="2">
                    {intl.formatMessage(
                      messages.outgoingMessageWithOutRecording
                    )}
                  </option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formTakingCalls">
              <Form.Label column lg={4} xl={3}>
                <FormattedMessage
                  id="form.answerphone.activate.taking-calls.label"
                  defaultMessage="Rufannahme"
                />
              </Form.Label>
              <Col lg={8} xl={9}>
                <Form.Control
                  custom
                  as="select"
                  name="takingCalls"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.takingCalls}
                  disabled={!tamActive}
                >
                  <option value="1">
                    {intl.formatMessage(messages.takeCallImmediately)}
                  </option>
                  <option value="2">
                    {intl.formatMessage(messages.timeDuration10secs)}
                  </option>
                  <option value="3">
                    {intl.formatMessage(messages.timeDuration15secs)}
                  </option>
                  <option value="4">
                    {intl.formatMessage(messages.timeDuration25secs)}
                  </option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              controlId="formIncomingMessageLength"
              className="mb-4"
            >
              <Form.Label column lg={4} xl={3}>
                <FormattedMessage
                  id="form.answerphone.activate.incoming-message-length.label"
                  defaultMessage="Nachrichtenlänge"
                />
              </Form.Label>
              <Col lg={8} xl={9}>
                <Form.Control
                  custom
                  as="select"
                  name="incomingMessageLength"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.incomingMessageLength}
                  disabled={!tamActive || disableMessageLength}
                >
                  <option value="1">
                    {intl.formatMessage(messages.timeDuration10secs)}
                  </option>
                  <option value="2">
                    {intl.formatMessage(messages.timeDuration20secs)}
                  </option>
                  <option value="3">
                    {intl.formatMessage(messages.timeDuration30secs)}
                  </option>
                  <option value="4">
                    {intl.formatMessage(messages.timeDuration60secs)}
                  </option>
                </Form.Control>
              </Col>
            </Form.Group>
            <h4 style={{ marginBottom: 20 }}>
              <FormattedMessage
                id="section.phone.page.answerphone.tab.activate.content.chose-outgoing-message.subheading"
                defaultMessage="Ansagetexte auswählen"
              />
            </h4>
            <Form.Group
              as={Row}
              controlId="formIncomingMessageLength"
              className="mb-4"
            >
              <Form.Label column lg={4} xl={3}>
                <FormattedMessage
                  id="form.answerphone.activate.choose-file.outgoing-message-with-recording.label"
                  defaultMessage="Ansage mit Aufzeichnung"
                />
              </Form.Label>
              <Col lg={8} xl={9}>
                <Form.File
                  id="outgoing-message-with-recording-file-upload"
                  name=""
                  label={intl.formatMessage(messages.fileUploadLabel)}
                  data-browse={intl.formatMessage(messages.chooseFileLabel)}
                  custom
                  value={values.outgoingMessageFileWithRecording}
                  className={
                    touched.outgoingMessageFileWithRecording &&
                    errors.outgoingMessageFileWithRecording
                      ? 'error'
                      : null
                  }
                  disabled={!tamActive}
                />
                <Form.Text className="text-muted">
                  {intl.formatMessage(messages.outgoingMessageFileType)}
                </Form.Text>
                {touched.outgoingMessageFileWithRecording &&
                errors.outgoingMessageFileWithRecording ? (
                  <Error>
                    <FormattedMessage
                      id={errors.outgoingMessageFileWithRecording}
                    />
                  </Error>
                ) : null}
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              controlId="formIncomingMessageLength"
              className="mb-4"
            >
              <Form.Label column lg={4} xl={3}>
                <FormattedMessage
                  id="form.answerphone.activate.choose-file.outgoing-message-without-recording.label"
                  defaultMessage="Nur Ansage"
                />
              </Form.Label>
              <Col lg={8} xl={9}>
                <Form.File
                  id="outgoing-message-without-recording-file-upload"
                  label={intl.formatMessage(messages.fileUploadLabel)}
                  data-browse={intl.formatMessage(messages.chooseFileLabel)}
                  custom
                  value={values.outgoingMessageFileWithOutRecording}
                  className={
                    touched.outgoingMessageFileWithOutRecording &&
                    errors.outgoingMessageFileWithOutRecording
                      ? 'error'
                      : null
                  }
                  disabled={!tamActive}
                />
                <Form.Text className="text-muted">
                  {intl.formatMessage(messages.outgoingMessageFileType)}
                </Form.Text>
                {touched.outgoingMessageFileWithOutRecording &&
                errors.outgoingMessageFileWithOutRecording ? (
                  <Error>
                    <FormattedMessage
                      id={errors.outgoingMessageFileWithOutRecording}
                    />
                  </Error>
                ) : null}
              </Col>
            </Form.Group>
            <Toast
              onClose={() => {
                setSaveMsgActive(false);
              }}
              show={saveMsgActive}
              delay={2500}
              autohide
              className="toast-save-msg toast-inbetween"
            >
              <Toast.Body>
                {intl.formatMessage(messages.changesAreAccepted)}
              </Toast.Body>
            </Toast>
          </div>
          <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
            <BackToOverview linkTarget="/phone/overview" />
            <div className="d-flex flex-row justify-content-end footer-buttons">
              <Button type="submit" variant="primary" disabled={!tamActive}>
                <FormattedMessage
                  id="button.save.label"
                  defaultMessage="Speichern"
                />
              </Button>
            </div>
          </Card.Footer>
        </Form>
      )}
    </Formik>
  );
}
