import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'form.error-message.mandatory-field',
  },
});
const surfstickPinRegExp = /^([0-9]{4})$/;
const surfstickPukRegExp = /^([0-9]{8})$/;

const validationSchema = Yup.object().shape({
  surfstickPin: Yup.string()
    .matches(surfstickPinRegExp, 'form.error-message.enter-four-digits')
    .required(),
  surfstickPuk: Yup.string().matches(
    surfstickPukRegExp,
    'form.error-message.enter-eight-digits'
  ),
});

export default validationSchema;
