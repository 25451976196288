import React from 'react';
import { FormattedMessage } from 'react-intl';

import formattedMessageValues from '../../../i18n/FormattedMessageValues';

const TabLoginCredentials = () => {
  return (
    <>
      <div className="tab-panel">
        <h4 style={{ marginBottom: 20 }}>
          <FormattedMessage
            id="section.internet.page.access.tab.login-credentials.content.headline"
            defaultMessage="Art der Internet-Verbindung"
          />
        </h4>
        <h5>
          <FormattedMessage
            id="text.using-a-flatrate.subheading"
            defaultMessage="Ich nutze eine Flatrate"
          />
        </h5>
        <p>
          <FormattedMessage
            id="text.using-a-flatrate.copy"
            defaultMessage="Ihre O<sub>2</sub>{nbsp}HomeBox ist dauerhaft mit dem Internet verbunden. Diese Einstellung ist Standard für Flatrate-Tarife.{br}Mit der an Ihrem Anschluss vorliegenden Technologie sind in diesem Menü keine Einstellungen notwendig."
            values={formattedMessageValues}
          />
        </p>
      </div>
    </>
  );
};

export default TabLoginCredentials;
