import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const Header = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
  height: 91px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  background: #5fa9d3;
  background-size: auto;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#131d40),
    color-stop(20%, #1f2460),
    color-stop(40%, #1e3680),
    color-stop(60%, #1b529d),
    color-stop(80%, #1967b0),
    to(#0e7bc1)
  );
  background-image: -webkit-linear-gradient(
    top,
    #131d40,
    #1f2460 20%,
    #1e3680 40%,
    #1b529d 60%,
    #1967b0 80%,
    #0e7bc1
  );
  background-image: linear-gradient(
    to bottom,
    #131d40,
    #1f2460 20%,
    #1e3680 40%,
    #1b529d 60%,
    #1967b0 80%,
    #0e7bc1
  );
`;

export const HeaderLogo = styled.div`
  height: 50px;
  z-index: 123456;

  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;

  color: #fff;
  vertical-align: bottom;
  font-size: 40px;
  margin-left: 18px;
  margin-top: 23px;

  :before {
    display: inline-block;
    font-family: o2icons;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '';
    vertical-align: bottom;
    font-size: 50px;
  }
`;

export const HeaderLang = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

export const LangBox = styled.div`
  padding-right: 18px;
  height: 91px;
`;

export const LangButton = styled(Button)`
  margin-left: 20px;
  @media only screen and (max-width: 575.98px) {
    margin-left: 10px;
  }
`;

export const LangBoxVersion = styled.div`
  color: #fff;
  font-size: 12px;
  line-height: 38px;
  /* display: none; */
`;

export const MobileButtonBox = styled.div`
  margin-left: 20px;
  @media only screen and (max-width: 575.98px) {
    margin-left: 0;
  }
`;

export const MobileBurgerButton = styled.div`
  cursor: pointer;
  :before {
    color: #fff;
    display: inline-block;
    font-family: o2icons;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f17e';
    vertical-align: bottom;
    font-size: 50px;
  }
}
`;

export const MobileCloseButton = styled.div`
  cursor: pointer;
  :before {
    color: #fff;
    display: inline-block;
    font-family: o2icons;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f147';
    vertical-align: bottom;
    font-size: 32px;
    margin-left: 9px;
    margin-right: 9px;
  }
}
`;

export const HelpBoxButton = styled.div`
  cursor: pointer;
  margin-left: 20px;
  :before {
    color: #fff;
    display: inline-block;
    font-family: o2icons;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f186';
    vertical-align: bottom;
    font-size: 32px;
    margin-left: 9px;
    margin-right: 9px;
  }
  @media only screen and (max-width: 575.98px) {
    margin-left: 5px;
  }
}
`;
