import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Col, Form, Modal, Row, Toast } from 'react-bootstrap';
import { Formik } from 'formik';

import BackToOverview from '../../../../components/buttons/BackToOverview';
import DividerLine from '../../../../components/layout/DividerLine';
import validationSchema from './FormPhoneBook.validationSchema';
import { Error } from '../../../../styles/Form.styles';
import { ContentCard } from '../../../../styles/Bootstrap.styles';
import PhoneBookTable from '../data/PhoneBookTable';
import phoneBookInitialData, {
  phoneBookEmptyData,
  prepareFormData,
  transformFormData,
  prepareEmptyFormData,
} from '../data/data';

export default function PhoneBookForm() {
  const [phoneBookData, setPhoneBookData] = useState([...phoneBookInitialData]);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const [saveMsgActive2, setSaveMsgActive2] = useState(false);
  const [saveMsgActive3, setSaveMsgActive3] = useState(false);
  const [currentFormEntry, setCurrentFormEntry] = useState(
    prepareFormData(phoneBookEmptyData)
  );
  const [showNewButton, setShowNewButton] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [successfulModal, setSuccessfulModal] = useState(false);
  const successfulModalRef = useRef(false);
  const [currentKeyForDeletion, setCurrentKeyForDeletion] = useState(null);
  const [showModalAllEntries, setShowModalAllEntries] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    // change value only if user confirmed in modal dialog
    if (successfulModalRef.current) {
      successfulModalRef.current = false;
      setSuccessfulModal(false);
      const phoneBookNewData = phoneBookData.filter((item) => {
        return item.key !== currentKeyForDeletion;
      });
      setPhoneBookData(phoneBookNewData);
      setCurrentKeyForDeletion(null);
    }
  }, [successfulModal, currentKeyForDeletion, phoneBookData]);

  const messages = defineMessages({
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
  });

  const deleteAllClassNames = showForm
    ? 'footer-left-button mr-4'
    : 'footer-left-button mr-2';

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleToast2Close = () => {
    setShowModal(false);
    setSaveMsgActive2(false);
    successfulModalRef.current = true;
    setSuccessfulModal(true);
  };

  const handleModalCloseAllEntries = () => {
    setShowModalAllEntries(false);
  };

  const handleToast3Close = () => {
    setShowModalAllEntries(false);
    setSaveMsgActive3(false);
    handleAllRowsDelete();
  };

  const handleRowDelete = (key) => {
    setCurrentKeyForDeletion(key);
    setShowModal(true);
  };

  const handleAllRowsDelete = () => {
    setPhoneBookData([]);
  };

  const handleRowSave = (key) => {
    const phoneBookRowEditData = phoneBookData.filter((item) => {
      return item.key === key;
    });
    setCurrentFormEntry(prepareFormData(phoneBookRowEditData[0]));
    setShowNewButton(!showNewButton);
    setShowForm(!showForm);
    setIsEdit(true);
  };

  const handleItemSave = (newFormValues) => {
    const newItem = transformFormData(newFormValues);
    if (!isEdit) {
      setPhoneBookData((oldArray) => [...oldArray, newItem]);
    } else {
      setPhoneBookData((oldArray) =>
        oldArray.map((item) => {
          if (item.key === newItem.key) {
            return newItem;
          }
          return item;
        })
      );
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={currentFormEntry}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        setCurrentFormEntry(values);
        setSaveMsgActive(true);
        resetForm({});
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ContentCard>
            <Card.Body>
              <h4 style={{ marginBottom: 20 }}>
                <FormattedMessage
                  id="section.phone.page.phonebook.subheading"
                  defaultMessage="Telefonbuchliste"
                />
              </h4>
              <PhoneBookTable
                rows={phoneBookData}
                onDelete={handleRowDelete}
                onSave={handleRowSave}
              />
              {showNewButton && (
                <div className="d-flex justify-content-start">
                  <Button
                    variant="primary"
                    onClick={() => {
                      const newPhoneBookEmptyData = prepareEmptyFormData();
                      setCurrentFormEntry(newPhoneBookEmptyData);
                      setShowNewButton(!showNewButton);
                      setShowForm(!showForm);
                    }}
                  >
                    <FormattedMessage
                      id="button.add-new-phone-book-entry.label"
                      defaultMessage="Eintrag hinzufügen"
                    />
                  </Button>
                </div>
              )}
              {showForm && (
                <>
                  <DividerLine />
                  <Form.Group as={Row} controlId="formFirstName">
                    <Form.Label column lg={4} xl={3}>
                      <FormattedMessage
                        id="text.first-name"
                        defaultMessage="Vorname"
                      />
                    </Form.Label>
                    <Col lg={8} xl={9}>
                      <Form.Control
                        type="text"
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        className={
                          touched.firstName && errors.firstName ? 'error' : null
                        }
                      />
                      {touched.firstName && errors.firstName ? (
                        <Error>
                          <FormattedMessage id={errors.firstName} />
                        </Error>
                      ) : null}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formLastName">
                    <Form.Label column lg={4} xl={3}>
                      <FormattedMessage
                        id="text.last-name"
                        defaultMessage="Nachname"
                      />
                    </Form.Label>
                    <Col lg={8} xl={9}>
                      <Form.Control
                        type="text"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        className={
                          touched.lastName && errors.lastName ? 'error' : null
                        }
                      />
                      {touched.lastName && errors.lastName ? (
                        <Error>
                          <FormattedMessage id={errors.lastName} />
                        </Error>
                      ) : null}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formNumberPrivate">
                    <Form.Label column lg={4} xl={3}>
                      <FormattedMessage
                        id="text.number-private"
                        defaultMessage="Rufnummer Privat"
                      />
                    </Form.Label>
                    <Col lg={8} xl={9}>
                      <Form.Control
                        type="text"
                        name="numberPrivate"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.numberPrivate}
                        className={
                          touched.numberPrivate && errors.numberPrivate
                            ? 'error'
                            : null
                        }
                      />
                      {touched.numberPrivate && errors.numberPrivate ? (
                        <Error>
                          <FormattedMessage id={errors.numberPrivate} />
                        </Error>
                      ) : null}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formNumberOffice">
                    <Form.Label column lg={4} xl={3}>
                      <FormattedMessage
                        id="text.number-office"
                        defaultMessage="Rufnummer Büro"
                      />
                    </Form.Label>
                    <Col lg={8} xl={9}>
                      <Form.Control
                        type="text"
                        name="numberOffice"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.numberOffice}
                        className={
                          touched.numberOffice && errors.numberOffice
                            ? 'error'
                            : null
                        }
                      />
                      {touched.numberOffice && errors.numberOffice ? (
                        <Error>
                          <FormattedMessage id={errors.numberOffice} />
                        </Error>
                      ) : null}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formNumberMobile">
                    <Form.Label column lg={4} xl={3}>
                      <FormattedMessage
                        id="text.number-mobile"
                        defaultMessage="Rufnummer Mobil"
                      />
                    </Form.Label>
                    <Col lg={8} xl={9}>
                      <Form.Control
                        type="text"
                        name="numberMobile"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.numberMobile}
                        className={
                          touched.numberMobile && errors.numberMobile
                            ? 'error'
                            : null
                        }
                      />
                      {touched.numberMobile && errors.numberMobile ? (
                        <Error>
                          <FormattedMessage id={errors.numberMobile} />
                        </Error>
                      ) : null}
                    </Col>
                  </Form.Group>
                </>
              )}
              <Toast
                onClose={() => {
                  setSaveMsgActive(false);
                  handleItemSave(currentFormEntry);
                  setShowForm(!showForm);
                  setIsEdit(false);
                  setShowNewButton(!showNewButton);
                }}
                show={saveMsgActive}
                delay={2500}
                autohide
                className="toast-save-msg"
              >
                <Toast.Body>
                  {intl.formatMessage(messages.changesAreAccepted)}
                </Toast.Body>
              </Toast>
              <Toast
                onClose={handleToast2Close}
                show={saveMsgActive2}
                delay={2500}
                autohide
                className="toast-save-msg"
              >
                <Toast.Body>
                  {intl.formatMessage(messages.changesAreAccepted)}
                </Toast.Body>
              </Toast>
              <Toast
                onClose={handleToast3Close}
                show={saveMsgActive3}
                delay={2500}
                autohide
                className="toast-save-msg"
              >
                <Toast.Body>
                  {intl.formatMessage(messages.changesAreAccepted)}
                </Toast.Body>
              </Toast>
            </Card.Body>
            <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
              <BackToOverview linkTarget="/phone/overview" />
              <div className="d-flex flex-row justify-content-end footer-buttons">
                {!showForm && (
                  <Button
                    variant="inactive"
                    className={deleteAllClassNames}
                    onClick={() => setShowModalAllEntries(true)}
                    disabled={showForm}
                  >
                    <FormattedMessage
                      id="button.delete-all.label"
                      defaultMessage="Alle löschen"
                    />
                  </Button>
                )}
                {showForm && (
                  <Button
                    variant="secondary"
                    className="mr-2"
                    onClick={() => {
                      setShowForm(!showForm);
                      setShowNewButton(!showNewButton);
                    }}
                  >
                    <FormattedMessage
                      id="button.cancel.label"
                      defaultMessage="Abbrechen"
                    />
                  </Button>
                )}
                <Button type="submit" variant="primary" disabled={!showForm}>
                  <FormattedMessage
                    id="button.save.label"
                    defaultMessage="Speichern"
                  />
                </Button>
              </div>
            </Card.Footer>
            <Modal
              show={showModal}
              onHide={handleModalClose}
              backdrop="static"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <FormattedMessage
                    id="text.warning"
                    defaultMessage="Warnung"
                  />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormattedMessage
                  id="text.delete-individual-phone-book-entry"
                  defaultMessage="Möchten Sie diesen Telefonbucheintrag löschen?"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                  <FormattedMessage
                    id="button.cancel.label"
                    defaultMessage="Abbrechen"
                  />
                </Button>
                <Button
                  variant="inactive"
                  onClick={() => {
                    handleModalClose();
                    setSaveMsgActive2(true);
                  }}
                >
                  <FormattedMessage
                    id="button.delete.label"
                    defaultMessage="Löschen"
                  />
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={showModalAllEntries}
              onHide={handleModalCloseAllEntries}
              backdrop="static"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <FormattedMessage
                    id="text.warning"
                    defaultMessage="Warnung"
                  />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormattedMessage
                  id="text.delete-all-phone-book-entries"
                  defaultMessage="Alle Einträge im Telefonbuch werden gelöscht."
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleModalCloseAllEntries}
                >
                  <FormattedMessage
                    id="button.cancel.label"
                    defaultMessage="Abbrechen"
                  />
                </Button>
                <Button
                  variant="inactive"
                  onClick={() => {
                    handleModalCloseAllEntries();
                    setSaveMsgActive3(true);
                  }}
                >
                  <FormattedMessage
                    id="button.delete-all.label"
                    defaultMessage="Alle löschen"
                  />
                </Button>
              </Modal.Footer>
            </Modal>
          </ContentCard>
        </Form>
      )}
    </Formik>
  );
}
