import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { OverlayTrigger, Table } from 'react-bootstrap';

import renderTooltipActive from '../../../../components/tables/TooltipActive';
import renderTooltipInactive from '../../../../components/tables/TooltipInactive';
import renderTooltipEdit from '../../../../components/tables/TooltipEdit';
import renderTooltipRemove from '../../../../components/tables/TooltipRemove';
import {
  TableInactiveBadge,
  TableActiveBadge,
  TableEditButton,
  TableRemoveButton,
} from '../../../../styles/Table.styles';
import generateKey from '../../../../helpers/uniqueKeys';

const UserManagementTable = ({ rows }) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="text.read" defaultMessage="Lesen" />
          </th>
          <th>
            <FormattedMessage id="text.write" defaultMessage="Schreiben" />
          </th>
          <th>
            <FormattedMessage id="text.user" defaultMessage="Benutzer" />
          </th>
          <th>
            <FormattedMessage id="text.action" defaultMessage="Aktion" />
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => {
          return (
            <tr key={generateKey(row.userName)}>
              <td>
                {row.read ? (
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltipActive}
                  >
                    <TableActiveBadge />
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltipInactive}
                  >
                    <TableInactiveBadge />
                  </OverlayTrigger>
                )}
              </td>
              <td>
                {row.write ? (
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltipActive}
                  >
                    <TableActiveBadge />
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltipInactive}
                  >
                    <TableInactiveBadge />
                  </OverlayTrigger>
                )}
              </td>
              <td>{row.userName}</td>
              <td>
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 250 }}
                  overlay={renderTooltipEdit}
                >
                  <TableEditButton variant="primary" />
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 250 }}
                  overlay={renderTooltipRemove}
                >
                  <TableRemoveButton variant="inactive" />
                </OverlayTrigger>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

UserManagementTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      read: PropTypes.bool.isRequired,
      write: PropTypes.bool.isRequired,
      userName: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default UserManagementTable;
