import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Form, OverlayTrigger, Table } from 'react-bootstrap';

import renderTooltipRemove from '../../../../components/tables/TooltipRemove';
import { TableRemoveButton } from '../../../../styles/Table.styles';
import generateKey from '../../../../helpers/uniqueKeys';

const ManageMessagesTable = ({ rows, rowValues }) => {
  const [rowValue, setRowValue] = useState(rowValues);

  const handleCheckBoxUpdate = (rowIndex) => {
    const newState = rowValue.map((rowVal, rIndex) => {
      if (rIndex === rowIndex) {
        return !rowVal;
      }
      return rowVal;
    });
    setRowValue(newState);
  };

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>
            <FormattedMessage
              id="text.caller-number"
              defaultMessage="Rufnummer des Anrufers"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.time-of-call"
              defaultMessage="Anrufzeitpunkt"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.call-duration.2"
              defaultMessage="Dauer (mm:ss)"
            />
          </th>
          <th>
            <FormattedMessage id="text.action" defaultMessage="Aktion" />
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => {
          const rowKey = generateKey(row.caller);
          const value = rowValue[rowIndex];
          return (
            <tr key={rowKey}>
              <td>
                <Form.Check
                  type="checkbox"
                  id={rowKey}
                  checked={value}
                  label=""
                  onChange={() => {
                    handleCheckBoxUpdate(rowIndex);
                  }}
                  custom
                />
              </td>
              <td>{row.caller}</td>
              <td>{row.timeOfCall}</td>
              <td>{row.length}</td>
              <td>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 250 }}
                  overlay={renderTooltipRemove}
                >
                  <TableRemoveButton variant="inactive" />
                </OverlayTrigger>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

ManageMessagesTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      caller: PropTypes.string.isRequired,
      timeOfCall: PropTypes.string.isRequired,
      length: PropTypes.string.isRequired,
    })
  ).isRequired,
  rowValues: PropTypes.arrayOf(PropTypes.bool).isRequired,
};

export default ManageMessagesTable;
