import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Col, Form, Row, Toast } from 'react-bootstrap';
import { Formik, useFormikContext } from 'formik';

import { Error } from '../../../../styles/Form.styles';
import validationSchema from './FormEncryption.validationSchema';
import PasswortStrengthWifi from '../../../../components/PasswordStrengthWifi';
import ToggleSwitch from '../../../../components/ToggleSwitch';
import BackToOverview from '../../../../components/buttons/BackToOverview';
import DividerLine from '../../../../components/layout/DividerLine';

const EncryptionVisibility = ({ toogleEncryptionVisibility }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    toogleEncryptionVisibility(values.encryptionType);
  }, [values, toogleEncryptionVisibility]);

  return null;
};

export default function FormEncryption() {
  const [encryption, setEncryption] = useState('wpa');
  const [wps, setWps] = useState(true);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const intl = useIntl();

  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.activated',
      defaultMessage: 'aktiviert',
    },
    stateDeactivated: {
      id: 'toggle-switch.deactivated',
      defaultMessage: 'deaktiviert',
    },
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
    wifiEncryptionModeWpsPsk: {
      id: 'form.wifi-encryption.mode.option.wps-psk',
      defaultMessage: 'WPS-PSK mit AES',
    },
    wifiEncryptionModeOpenOwe: {
      id: 'form.wifi-encryption.mode.option.open-owe',
      defaultMessage: 'Open/OWE, OWE, WPA2/WPA3, WPA3 SAE',
    },
  });

  const wpsClassNames = wps
    ? 'o2-badge o2-badge-green mb-3'
    : 'o2-badge o2-badge-grey mb-3';

  const toogleEncryptionVisibility = (encryptionType) => {
    if (encryptionType === 'wpa') {
      setEncryption(true);
    }
    if (encryptionType === 'none') {
      setEncryption(false);
    }
  };

  return (
    <Formik
      initialValues={{
        encryptionType: 'wpa',
        encryptionMode: 2,
        encryptionKey: '&j839uj801Y#293x',
      }}
      validationSchema={validationSchema}
      onSubmit={(event, actions) => {
        setSaveMsgActive(true);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="tab-panel">
            <h4 style={{ marginBottom: 20 }}>
              <FormattedMessage
                id="section.lan.page.wifi.tab.wifi-encryption.content.headline"
                defaultMessage="WLAN-Basisstation verschlüsseln"
              />
            </h4>
            <p className="mb-2rem">
              <FormattedMessage
                id="section.lan.page.wifi.tab.wifi-encryption.content.copy"
                defaultMessage="Legen Sie den Modus für die WLAN-Verschlüsselung fest, und geben Sie einen Schlüssel ein. Bitte beachten Sie, dass Sie damit den auf der Geräterückseite angegebenen WLAN-Schlüssel überschreiben."
              />
            </p>
            <h5 style={{ marginBottom: 20 }}>
              <FormattedMessage
                id="section.lan.page.wifi.tab.wifi-encryption.content.subheading"
                defaultMessage="WLAN-Verschlüsselung"
              />
            </h5>
            <EncryptionVisibility
              toogleEncryptionVisibility={toogleEncryptionVisibility}
            />
            <Form.Group as={Row} controlId="formEncryptionType">
              <Col xs={12}>
                <Form.Check type="radio" id="encryptionWpa" custom>
                  <Form.Check.Input
                    type="radio"
                    name="encryptionType"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="wpa"
                    checked={values.encryptionType === 'wpa'}
                  />
                  <Form.Check.Label>
                    <FormattedMessage
                      id="form.wifi-encryption.type.wpa.label"
                      defaultMessage="WLAN-Verschlüsselung verwenden"
                    />
                  </Form.Check.Label>
                </Form.Check>
              </Col>
              <Col xs={12}>
                <Form.Check type="radio" id="encryptionNone" custom>
                  <Form.Check.Input
                    type="radio"
                    name="encryptionType"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="none"
                    checked={values.encryptionType === 'none'}
                  />
                  <Form.Check.Label>
                    <FormattedMessage
                      id="form.wifi-encryption.type.none.label"
                      defaultMessage="ohne Verschlüsselung"
                    />
                  </Form.Check.Label>
                </Form.Check>
              </Col>
            </Form.Group>
            {encryption && (
              <>
                <Form.Group as={Row} controlId="formEncryptionMode">
                  <Form.Label column lg={2}>
                    <FormattedMessage
                      id="form.wifi-encryption.mode.label"
                      defaultMessage="WLAN-Modus"
                    />
                  </Form.Label>
                  <Col lg={10}>
                    <Form.Control
                      custom
                      as="select"
                      name="encryptionMode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.encryptionMode}
                    >
                      <option value="2">
                        {intl.formatMessage(messages.wifiEncryptionModeWpsPsk)}
                      </option>
                      <option value="1">
                        {intl.formatMessage(messages.wifiEncryptionModeOpenOwe)}
                      </option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formEncryptionKey">
                  <Form.Label column lg={2}>
                    <FormattedMessage
                      id="form.wifi-encryption.key.label"
                      defaultMessage="WLAN-Schlüssel"
                    />
                  </Form.Label>
                  <Col lg={10}>
                    <Form.Control
                      type="text"
                      name="encryptionKey"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.encryptionKey}
                      className={
                        touched.encryptionKey && errors.encryptionKey
                          ? 'error'
                          : null
                      }
                    />
                    <PasswortStrengthWifi password={values.encryptionKey} />
                    <Form.Text className="text-muted">
                      <FormattedMessage
                        id="form.wifi-encryption.key.hint"
                        defaultMessage="Ihr WLAN-Schlüssel muss zwischen 8 und 63 Zeichen lang sein."
                      />
                    </Form.Text>
                    {touched.encryptionKey && errors.encryptionKey ? (
                      <Error>
                        <FormattedMessage id={errors.encryptionKey} />
                      </Error>
                    ) : null}
                  </Col>
                </Form.Group>
              </>
            )}
            <DividerLine />
            <p>
              <span className="font-weight-bold">
                <FormattedMessage
                  id="section.lan.page.wifi.tab.wifi-encryption.content.wps.label"
                  defaultMessage="WPS aktivieren (Push-Button-Methode)"
                />
              </span>
            </p>
            <ToggleSwitch
              id="toggleSwitchWps"
              checked={wps}
              onChange={(checked) => setWps(checked)}
              small
            />
            <span className={wpsClassNames}>
              <FormattedMessage
                id="section.lan.page.wifi.tab.wifi-encryption.content.wps.state"
                defaultMessage="WPS ist"
              />{' '}
              {wps
                ? intl.formatMessage(messages.stateActivated)
                : intl.formatMessage(messages.stateDeactivated)}
            </span>
            <Toast
              onClose={() => setSaveMsgActive(false)}
              show={saveMsgActive}
              delay={2500}
              autohide
              className="toast-save-msg"
            >
              <Toast.Body>
                {intl.formatMessage(messages.changesAreAccepted)}
              </Toast.Body>
            </Toast>
          </div>
          <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
            <BackToOverview linkTarget="/lan/overview" />
            <div className="d-flex flex-row justify-content-end footer-buttons">
              <Button type="submit" variant="primary">
                <FormattedMessage
                  id="button.save.label"
                  defaultMessage="Speichern"
                />
              </Button>
            </div>
          </Card.Footer>
        </Form>
      )}
    </Formik>
  );
}
