// manage messages
const answerphoneMessagesData = [
  {
    caller: '+49 40 123456789',
    timeOfCall: '01.11.2020 - 17:23 Uhr',
    length: '00:57',
  },
  {
    caller: '+49 89 123456789',
    timeOfCall: '02.10.2020 - 02:41 Uhr',
    length: '00:36',
  },
  {
    caller: '+49 211 123456789',
    timeOfCall: '08.09.2020 - 11:58 Uhr',
    length: '00:41',
  },
];

export const initialRowValues = [false, false, false];

// config answerphone
export const configRowValuesData = [
  {
    outGoingMessage:
      'form.answerphone.activate.choose-file.outgoing-message-with-recording.label',
    values: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
  },
  {
    outGoingMessage:
      'form.answerphone.activate.choose-file.outgoing-message-without-recording.label',
    values: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
  },
];

export default answerphoneMessagesData;
