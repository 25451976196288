import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Container, Row, Card } from 'react-bootstrap';

import ScrollToTop from '../../components/ScrollToTop';
import { ColMain } from '../../styles/Bootstrap.styles';
import { RcdH4, RcdH5, RcdUl } from '../../styles/Shared.styles';

export default function PageReadme() {
  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>Readme</h3>
            <p className="mb-5">
              <span className="font-weight-bold">
                Click dummy for HomeBox 3
              </span>
            </p>
            <RcdH4>Readme files</RcdH4>
            <p>
              Please see <code>README.md</code> for used development environment
              and <code>README-React.md</code> (which is the standard README of
              a React Project created with <code>create-react-app</code>) in the
              project root folder.
            </p>
            <RcdH4>Change log</RcdH4>
            <RcdH5>v0.1.33 (2021-01-14)</RcdH5>
            <RcdUl>
              <li>
                Changed functionality of button „Save“ at the bottom on tab
                „Configure“ on page:{' '}
                <Link to="/phone/answerphone">
                  Telephony - Answering machine
                </Link>
                : this button will be enabled by default and allows saving of
                changed values eiher in section „Assign a greeting“ and/or
                „Email notification“.
              </li>
            </RcdUl>
            <RcdH5>v0.1.32 (2020-12-18)</RcdH5>
            <RcdUl>
              <li>
                Page extended: extended form to simulate the new entry“
                functionality on tab „Analogue devices“ on page:{' '}
                <Link to="/phone/config">Telephony -­ Configuration</Link>,
                error message will only be shown if user tries to add more than
                two analogue devices. If one analogue device exists, the new
                device will automatically get the corresponding analogue line
                not in use.
              </li>
              <li>
                Form field „Incoming message length“ will be diabled if
                „Greeting only“ was selected in field „Greeting mode“ on page:{' '}
                <Link to="/phone/answerphone">
                  Telephony - Answering machine
                </Link>
              </li>
              <li>
                Page extended: added form to simulate the „edit entry“
                functionality and added a modal dialogue in order to confirm the
                deletion of a device entry on tab „Analogue devices“ on page:{' '}
                <Link to="/phone/config">Telephony -­ Configuration</Link>
              </li>
              <li>
                Page extended: added form to simulate the „edit entry“
                functionality and added a modal dialogue in order to confirm the
                deletion of a device entry in section „Registered DECT phones“
                on tab „DECT“ on page:{' '}
                <Link to="/phone/config">Telephony -­ Configuration</Link>
              </li>
            </RcdUl>
            <RcdH5>v0.1.31 (2020-12-07)</RcdH5>
            <RcdUl>
              <li>Added missing english translations</li>
              <li>form error messages are now translated</li>
            </RcdUl>
            <RcdH5>v0.1.30 (2020-12-04)</RcdH5>
            <RcdUl>
              <li>Added english translations</li>
            </RcdUl>
            <RcdH5>v0.1.29 (2020-11-24)</RcdH5>
            <RcdUl>
              <li>UI Review fixes</li>
            </RcdUl>
            <RcdH5>v0.1.28 (2020-11-23)</RcdH5>
            <RcdUl>
              <li>
                created new PasswordStrengthWifi component: only from a password
                length of 8 characters on, a strength evaluation will be shown:
                used on these pages: on tab „Encryption“ on page{' '}
                <Link to="/lan/wi-fi">Home network -­ Wi-Fi</Link> and on tab
                „Guest access“ on page{' '}
                <Link to="/lan/wi-fi-guest-access">
                  Home network - Guest Wi-Fi access
                </Link>
              </li>
              <li>
                Page added: <Link to="/login">Login</Link>
              </li>
            </RcdUl>
            <RcdH5>v0.1.27 (2020-11-22)</RcdH5>
            <RcdUl>
              <li>
                Page extended: added form to simulate new entry functionality:{' '}
                <Link to="/phone/phonebook">Telephony -­ Phone book</Link>
              </li>
              <li>
                Page extended: added modal dialogue to confirm deletion of a
                dnydns entry:{' '}
                <Link to="/internet/dyndns">Internet -­ DynDNS</Link>
              </li>
            </RcdUl>
            <RcdH5>v0.1.26 (2020-11-20)</RcdH5>
            <RcdUl>
              <li>
                added a modal dialogue on tab „Base station“ on page:{' '}
                <Link to="/lan/wi-fi">Home network -­ Wi-Fi</Link>: while trying
                to save the base station form: given the conditon that one SSID
                for both base stations is deactivated, both base stations are
                activated and both SSIDs are different: then show a modal
                dialogue with a notification concerning band steering
              </li>
            </RcdUl>
            <RcdH5>v0.1.25 (2020-11-19)</RcdH5>
            <RcdUl>
              <li>
                fixed bug on tab „Base station“ on page:{' '}
                <Link to="/lan/wi-fi">Home network -­ Wi-Fi</Link>
              </li>
            </RcdUl>
            <RcdH5>v0.1.24 (2020-11-19)</RcdH5>
            <RcdUl>
              <li>
                Changed sidebar menu: on click on sidebar submenu title:
                redirect directly to first page of submenu.
              </li>
              <li>
                Page changed:{' '}
                <Link to="/system/led-management">System - LED management</Link>
                : added text to help box
              </li>
              <li>
                Page changed: <Link to="/lan/wi-fi">Home network - Wi-Fi</Link>:
                several adjustments to new wifi settings
              </li>
            </RcdUl>
            <RcdH5>v0.1.23</RcdH5>
            <RcdUl>
              <li>
                Page finished:{' '}
                <Link to="/phone/answerphone">
                  Telephony - Answering machine
                </Link>
              </li>
              <li>
                Step 1 in setup wizard now shows a sequence of states (with
                loading spinners)
              </li>
            </RcdUl>
            <RcdH5>v0.1.22</RcdH5>
            <RcdUl>
              <li>
                fixed bug for internet explorer 11: using correct height in
                modal dialogue on tab „Provide access“ on page:{' '}
                <Link to="/lan/wi-fi-guest-access">
                  Home network - Guest Wi-Fi access
                </Link>
              </li>
              <li>
                Page added:{' '}
                <Link to="/system/led-management">System - LED management</Link>
              </li>
              <li>
                Page changed:{' '}
                <Link to="/system/password">System -­ Password</Link>: added old
                password field
              </li>
              <li>
                changed wi-fi mode options and form validation of wpa key
                (length from 8 to 63) on tab „Encryption“ on page:{' '}
                <Link to="/lan/wi-fi">Home network -­ Wi-Fi</Link>
              </li>
            </RcdUl>
            <RcdH5>v0.1.21</RcdH5>
            <RcdUl>
              <li>
                Page finished:{' '}
                <Link to="/lan/wi-fi-guest-access">
                  Home network - Guest Wi-Fi access
                </Link>
              </li>
            </RcdUl>
            <RcdH5>v0.1.20</RcdH5>
            <RcdUl>
              <li>
                Added modal dialogue on link „Setup wizard“ in sidebar menu.
              </li>
              <li>
                Setup wizard will display different text content depending on
                whether it is being run for the first time or not.
                <br />
                It differs in a note that you can use the intial password which
                can be found on the back of the router or use one&apos;s own
                password.
              </li>
              <li>Setup wizard is now first page of click dummy.</li>
            </RcdUl>
            <RcdH5>v0.1.19</RcdH5>
            <RcdUl>
              <li>
                Page finished:{' '}
                <Link to="/internet/surfstick">
                  Internet - Access via mobile broadband dongle
                </Link>
              </li>
              <li>
                Added „demo“ functionality on page{' '}
                <Link to="/internet/surfstick">
                  Internet - Access via mobile broadband dongle
                </Link>
                : if one enters „9999“ as PIN, this should simulate the
                functionality of PUK input.
                <br />
                If one enters „99999999“ subsequently as PUK, the error message
                will disappear.
              </li>
            </RcdUl>
            <RcdH5>v0.1.18</RcdH5>
            <RcdUl>
              <li>
                Page finished:{' '}
                <Link to="/lan/overview">Home network - Overview</Link>
              </li>
              <li>
                Page finished: <Link to="/assistant">Setup wizard</Link>
              </li>
            </RcdUl>
            <RcdH5>v0.1.17</RcdH5>
            <RcdUl>
              <li>
                Used hardware/provider flag „DS-Lite“ to disable fields on
                pages: <Link to="/lan/config">Home network - LAN</Link> and{' '}
                <Link to="/lan/port-forwarding">
                  Home network -­ Port forwarding
                </Link>
              </li>
              <li>
                Page finished: <Link to="/lan/config">Home network - LAN</Link>
              </li>
              <li>
                Page finished: <Link to="/lan/wi-fi">Home network - Wi-Fi</Link>
              </li>
              <li>
                Added Link on version field in header to page:{' '}
                <Link to="/hardware">Hardware&nbsp;/ Provider page</Link> to
                change value in click dummy ui. After changing a value on this
                page, please use menu to navigate to the page you want to see
                the desired effect. Do not use UI in a new browser tab, because
                this will reset chosen values to default values.
              </li>
              <li>
                New <span className="font-weight-bold">hidden page</span> to set
                hardware/provider flags (e.g. „DS-Lite“):{' '}
                <Link to="/hardware">Hardware&nbsp;/ Provider page</Link>
                <br />
                These flags will have effect on all pages to simulate router /
                provider conditions.
              </li>
            </RcdUl>
            <RcdH5>v0.1.16</RcdH5>
            <RcdUl>
              <li>
                Page finished:{' '}
                <Link to="/phone/config">Telephony -­ Configuration</Link>
              </li>
              <li>
                Added message „The registration process of your DECT base
                station has started ...“ after a click on the button „Add new
                DECT handset“. Added main error message „The DECT phone could
                not be registered ...“ after former message. Please see an
                example on page{' '}
                <Link to="/phone/config">Telephony -­ Configuration</Link> on
                tab „DECT“.
              </li>
              <li>
                Added modal dialogue in toggle switches in order to confirm the
                new state. Please see an implementation on page{' '}
                <Link to="/phone/config">Telephony -­ Configuration</Link> on
                tab „DECT“.
              </li>
              <li>
                Added main error message above footer button, please see an
                example on page{' '}
                <Link to="/phone/config">Telephony -­ Configuration</Link> on
                tab „Analogue devices“. The error message will appear if two
                analogue devices are listed in the table and one clicks on the
                button „Add new analogue device“.
              </li>
            </RcdUl>
            <RcdH5>v0.1.15</RcdH5>
            <RcdUl>
              <li>
                Page finished:{' '}
                <Link to="/phone/numbers">Telephony -­ Call number plan</Link>
              </li>
              <li>
                Added overview of answerphone on page{' '}
                <Link to="/phone/overview">Telephony - Overview</Link>
              </li>
              <li>
                Coloured status messages (like „Connected“ / „Not connected“)
                have been enlarged.
              </li>
              <li>
                Page finished:{' '}
                <Link to="/phone/phonebook">Telephony -­ Phone book</Link>
              </li>
            </RcdUl>
            <RcdH5>v0.1.14</RcdH5>
            <RcdUl>
              <li>
                Page finished: <Link to="/system/reset">System -­ Reset</Link>
              </li>
              <li>
                Page finished:{' '}
                <Link to="/system/settings">System -­ Save settings</Link>
              </li>
              <li>
                Page finished:{' '}
                <Link to="/security/igd-upnp">Security -­ IGD/UPnP</Link>
              </li>
              <li>
                Page finished:{' '}
                <Link to="/security/mac-filter">
                  Security -­ Wi-Fi MAC address filter
                </Link>
              </li>
            </RcdUl>
            <RcdH5>v0.1.13</RcdH5>
            <RcdUl>
              <li>
                Page extended: added form to simulate new entry functionality:{' '}
                <Link to="/internet/dyndns">Internet -­ DynDNS</Link>
              </li>
              <li>
                Page finished:{' '}
                <Link to="/lan/port-forwarding">
                  Home network -­ Port forwarding
                </Link>
              </li>
              <li>
                Page finished: <Link to="/lan/usb">Home network -­ USB</Link>
              </li>
              <li>
                Page finished:{' '}
                <Link to="/lan/mediacenter">Home network -­ MediaCenter</Link>
              </li>
            </RcdUl>
            <RcdH5>v0.1.12</RcdH5>
            <RcdUl>
              <li>
                All Buttons „Back to Overview“ are linking now to correct
                overview pages.
              </li>
              <li>
                Page extended: added modal dialogue in order to confirm the
                deletion of all call list entries:{' '}
                <Link to="/phone/call-list">Telephony -­ Call list</Link>
              </li>
              <li>
                Page finished:{' '}
                <Link to="/phone/overview">Telephony - Overview</Link>
              </li>
              <li>
                Page finished:{' '}
                <Link to="/system/password">System -­ Password</Link>
              </li>
            </RcdUl>
            <RcdH5>v0.1.11</RcdH5>
            <RcdUl>
              <li>Password strength meter reduced to three steps</li>
              <li>
                Page finished:{' '}
                <Link to="/phone/call-list">Telephony -­ Call list</Link>
              </li>
              <li>
                Page finished:{' '}
                <Link to="/internet/dyndns">Internet -­ DynDNS</Link>
              </li>
            </RcdUl>
            <RcdH5>v0.1.10</RcdH5>
            <RcdUl>
              <li>
                Page finished: <Link to="/overview">Overview</Link>
              </li>
              <li>
                Page finished:{' '}
                <Link to="/system/remote-control">
                  System -­ Remote maintenance
                </Link>
              </li>
              <li>
                Added Password strength meter and WPA3 option on tab{' '}
                „Encryption“ on page:{' '}
                <Link to="/lan/wi-fi">Home network -­ Wi-Fi</Link>
              </li>
            </RcdUl>
            <RcdH5>v0.1.9</RcdH5>
            <RcdUl>
              <li>
                Show Save Message above Save Button:
                <br />
                For example, please see on tab „Base station“ on page:
                <Link to="/lan/wi-fi">Home network -­ Wi-Fi</Link>
              </li>
              <li>
                Added Password strength meter on page:{' '}
                <Link to="/system/remote-control">
                  System -­ Remote maintenance
                </Link>
              </li>
              <li>
                Page finished:{' '}
                <Link to="/internet/access">Internet -­ Configure access</Link>
              </li>
              <li>
                Page finished:{' '}
                <Link to="/system/legal-information">
                  System -­ Legal information
                </Link>
              </li>
              <li>
                Show different content in help box depending on the selected
                tab:
                <br />
                <Link to="/internet/access">Internet -­ Configure access</Link>
                <br />
                <Link to="/phone/config">Telephony -­ Configuration</Link>
                <br />
                <Link to="/phone/numbers">Telephony -­ Call number plan</Link>
                <br />
                <Link to="/phone/answerphone">
                  Telephony -­ Answering machine
                </Link>
                <br />
                <Link to="/lan/config">Home network -­ LAN</Link>
                <br />
                <Link to="/lan/wi-fi">Home network -­ Wi-Fi</Link>
                <br />
                <Link to="/lan/wi-fi-guest-access">
                  Home network -­ Guest Wi-Fi access
                </Link>
                <br />
                <Link to="/lan/usb">Home network -­ USB</Link>
              </li>
            </RcdUl>
            <RcdH5>v0.1.8</RcdH5>
            <RcdUl>
              <li>
                Show different content in help box depending on the selected
                tab:
                <br />
                <Link to="/lan/wi-fi-guest-access">
                  Home network -­ Guest Wi-Fi access
                </Link>
              </li>
              <li>
                Tabs Component on all relevant pages:
                <br />
                <Link to="/internet/access">Internet -­ Configure access</Link>
                <br />
                <Link to="/phone/config">Telephony -­ Configuration</Link>
                <br />
                <Link to="/phone/numbers">Telephony -­ Call number plan</Link>
                <br />
                <Link to="/phone/answerphone">
                  Telephony -­ Answering machine
                </Link>
                <br />
                <Link to="/lan/config">Home network -­ LAN</Link>
                <br />
                <Link to="/lan/wi-fi">Home network -­ Wi-Fi</Link>
                <br />
                <Link to="/lan/wi-fi-guest-access">
                  Home network -­ Guest Wi-Fi access
                </Link>
                <br />
                <Link to="/lan/port-forwarding">
                  Home network -­ Port forwarding
                </Link>
                <br />
                <Link to="/lan/usb">Home network -­ USB</Link>
              </li>
              <li>
                When entering a page, it scrolls up to the top of the page.
                <br />
                This was necessary because changing from a page with very long
                text to a page with shorter text, the scroll position remains at
                the bottom of the new page.
              </li>
            </RcdUl>
            <RcdH5>v0.1.7</RcdH5>
            <RcdUl>
              <li>
                The navigation option for help was added.
                <br />
                In the upper right corner the help icon is displayed for smaller
                resolutions (small desktop, tablet). At smartphone resolution
                the help icon is displayed between language buttons and
                hamburger menu.
                <br />
                By clicking the help button, the page automatically scrolls to
                the help box at the bottom of the page. The animated scrolling
                to the help box should make clear where the help is located on
                the page in these resolutions.
                <br />
                On large desktops the help is always displayed on the right.
              </li>
              <li>
                A back-to-top button is displayed on pages with a lot of
                content.
              </li>
            </RcdUl>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Title>
                  <span
                    style={{
                      display: 'inline-block',
                      padding: '5px 10px',
                      backgroundColor: '#f4364c',
                      color: '#fff',
                    }}
                  >
                    IMPORTANT INFORMATION
                  </span>
                </Card.Title>
                <Card.Text>
                  <span className="font-weight-bold">Please</span> note that
                  this page{' '}
                  <span className="font-weight-bold">is not part</span> of the
                  HomeBox 3 web interface.
                </Card.Text>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
