import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { OverlayTrigger, Table } from 'react-bootstrap';

import renderTooltipRemove from '../../../../components/tables/TooltipRemove';
import { TableRemoveButton } from '../../../../styles/Table.styles';
import generateKey from '../../../../helpers/uniqueKeys';

const MassStorageTable = ({ rows }) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>
            <FormattedMessage
              id="text.connected-device"
              defaultMessage="Angeschlossenes Gerät"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.memory-size"
              defaultMessage="Speichergröße"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.free-space"
              defaultMessage="Freier Speicher"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.remove-safely"
              defaultMessage="Sicher entfernen"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => {
          return (
            <tr key={generateKey(row.deviceName)}>
              <td>{row.deviceName}</td>
              <td>{row.memorySize}</td>
              <td>{row.freeSize}</td>
              <td>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 250 }}
                  overlay={renderTooltipRemove}
                >
                  <TableRemoveButton variant="inactive" />
                </OverlayTrigger>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

MassStorageTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string.isRequired,
      memorySize: PropTypes.string.isRequired,
      freeSize: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default MassStorageTable;
