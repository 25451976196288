/* eslint-disable no-param-reassign */
import { v4 as uuidv4 } from 'uuid';

const phoneBookData = [
  {
    key: uuidv4(),
    lastName: 'Mustermann',
    firstName: 'Max',
    phoneNumbers: [
      {
        key: uuidv4(),
        number: '0228 65335641',
        numberType: 'private',
      },
      {
        key: '',
        number: '',
        numberType: 'office',
      },
      {
        key: uuidv4(),
        number: '0176 65335641',
        numberType: 'mobile',
      },
    ],
  },
  {
    key: uuidv4(),
    lastName: 'Musterfrau',
    firstName: 'Erika',
    phoneNumbers: [
      {
        key: uuidv4(),
        number: '0221 65335642',
        numberType: 'private',
      },
      {
        key: uuidv4(),
        number: '0211 65335642',
        numberType: 'office',
      },
      {
        key: uuidv4(),
        number: '0177 65335642',
        numberType: 'mobile',
      },
    ],
  },
];

export const phoneBookEmptyData = {
  key: '',
  lastName: '',
  firstName: '',
  phoneNumbers: [
    {
      key: '',
      number: '',
      numberType: 'private',
    },
    {
      key: '',
      number: '',
      numberType: 'office',
    },
    {
      key: '',
      number: '',
      numberType: 'mobile',
    },
  ],
};

export const prepareFormData = (dataEntry) => {
  return {
    firstName: dataEntry.firstName,
    lastName: dataEntry.lastName,
    numberPrivate: dataEntry.phoneNumbers[0].number,
    numberOffice: dataEntry.phoneNumbers[1].number,
    numberMobile: dataEntry.phoneNumbers[2].number,
    formerDataSet: dataEntry,
  };
};

export const prepareEmptyFormData = () => {
  const emptyDataSet = JSON.parse(JSON.stringify(phoneBookEmptyData));
  emptyDataSet.key = uuidv4();
  emptyDataSet.phoneNumbers[0].key = uuidv4();
  emptyDataSet.phoneNumbers[1].key = uuidv4();
  emptyDataSet.phoneNumbers[2].key = uuidv4();
  return {
    firstName: phoneBookEmptyData.firstName,
    lastName: phoneBookEmptyData.lastName,
    numberPrivate: phoneBookEmptyData.phoneNumbers[0].number,
    numberOffice: phoneBookEmptyData.phoneNumbers[1].number,
    numberMobile: phoneBookEmptyData.phoneNumbers[2].number,
    formerDataSet: emptyDataSet,
  };
};

export const transformFormData = (formValues) => {
  formValues.formerDataSet.firstName = formValues.firstName;
  formValues.formerDataSet.lastName = formValues.lastName;
  if (
    formValues.numberPrivate !== '' &&
    formValues.formerDataSet.phoneNumbers[0].key === ''
  ) {
    formValues.formerDataSet.phoneNumbers[0].key = uuidv4();
  }
  if (
    formValues.numberOffice !== '' &&
    formValues.formerDataSet.phoneNumbers[1].key === ''
  ) {
    formValues.formerDataSet.phoneNumbers[1].key = uuidv4();
  }
  if (
    formValues.numberMobile !== '' &&
    formValues.formerDataSet.phoneNumbers[2].key === ''
  ) {
    formValues.formerDataSet.phoneNumbers[2].key = uuidv4();
  }
  formValues.formerDataSet.phoneNumbers[0].number = formValues.numberPrivate;
  formValues.formerDataSet.phoneNumbers[1].number = formValues.numberOffice;
  formValues.formerDataSet.phoneNumbers[2].number = formValues.numberMobile;
  return formValues.formerDataSet;
};

export default phoneBookData;
