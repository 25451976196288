import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const HourSelectField = ({ name, disabled, initialValue }) => {
  const [state, setState] = useState(initialValue);

  return (
    <Form.Control
      custom
      as="select"
      name={name}
      onChange={(e) => setState(e.target.value)}
      value={state}
      disabled={disabled}
      className="field-timer"
    >
      {[...Array(24).keys()].map((hour) => {
        return (
          <option key={hour} value={hour}>
            {hour < 10 ? `0${hour}` : hour}
          </option>
        );
      })}
    </Form.Control>
  );
};

HourSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  initialValue: PropTypes.string.isRequired,
};

export default HourSelectField;
