import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import generateKey from '../../../helpers/uniqueKeys';

const LanConnectionTable = ({ rows }) => {
  return (
    <div className="tab-panel">
      <h4 style={{ marginBottom: 20 }}>
        <FormattedMessage
          id="section.lan.page.overview.lan-connection.content.headline"
          defaultMessage="LAN-Anschluss"
          values={{
            sub: (chunks) => <sub>{chunks}</sub>,
            br: <br />,
          }}
        />
      </h4>
      <Table responsive>
        <thead>
          <tr>
            <th>
              <FormattedMessage
                id="text.connected-device"
                defaultMessage="Angeschlossenes Gerät"
              />
            </th>
            <th>
              <FormattedMessage
                id="text.ipv4-address"
                defaultMessage="IPv4-Adresse"
              />
            </th>
            <th>
              <FormattedMessage
                id="text.ipv6-address"
                defaultMessage="IPv6-Adresse"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => {
            return (
              <tr key={generateKey(row.ipV4Address)}>
                <td>{row.connectedDevice}</td>
                <td>{row.ipV4Address}</td>
                <td>{row.ipV6Address}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

LanConnectionTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      connectedDevice: PropTypes.string.isRequired,
      ipV4Address: PropTypes.string.isRequired,
      ipV6Address: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default LanConnectionTable;
