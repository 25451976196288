import React from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { Form, Table } from 'react-bootstrap';

import { buildFullMacAddress } from '../../../../helpers/network';

const AvailableMacFilterTable = ({ rows, onCopy }) => {
  const intl = useIntl();

  const messages = defineMessages({
    macAddressOf: {
      id: 'text.mac-address-of',
      defaultMessage: 'MAC-Adresse von',
    },
    newMacAddress: {
      id: 'text.new-mac-address',
      defaultMessage: 'Neue MAC-Adresse',
    },
    textOther: {
      id: 'text.other-mac-address',
      defaultMessage: 'Andere',
    },
  });

  const handleCopy = (event, row) => {
    onCopy(row);
  };

  return (
    <Form>
      <Table responsive>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>
              <FormattedMessage
                id="text.mac-address"
                defaultMessage="MAC-Adresse"
              />
            </th>
            <th>
              <FormattedMessage
                id="form.ipv4.ip-address.label"
                defaultMessage="IP-Adresse"
              />
            </th>
            <th>
              <FormattedMessage id="text.host-name" defaultMessage="Hostname" />
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => {
            return (
              <tr key={row.key}>
                <td className="only-checkbox">
                  <Form.Check
                    label="&nbsp;"
                    type="radio"
                    name="avilableMacAddress"
                    id={row.key}
                    value={row.hostName}
                    onChange={($event) => handleCopy($event, row)}
                    custom
                  />
                </td>
                <td>
                  {buildFullMacAddress(
                    row.macAddressByte1,
                    row.macAddressByte2,
                    row.macAddressByte3,
                    row.macAddressByte4,
                    row.macAddressByte5,
                    row.macAddressByte6
                  )}
                </td>
                <td>{row.ipV4Address}</td>
                <td>{row.hostName}</td>
              </tr>
            );
          })}
          <tr>
            <td className="only-checkbox">
              <Form.Check
                label="&nbsp;"
                type="radio"
                name="avilableMacAddress"
                id="otherMacAddress"
                value="other"
                onChange={($event) => handleCopy($event, 'other')}
                defaultChecked
                custom
              />
            </td>
            <td colSpan={3}>{intl.formatMessage(messages.textOther)}</td>
          </tr>
        </tbody>
      </Table>
    </Form>
  );
};

AvailableMacFilterTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      macAddressByte1: PropTypes.string.isRequired,
      macAddressByte2: PropTypes.string.isRequired,
      macAddressByte3: PropTypes.string.isRequired,
      macAddressByte4: PropTypes.string.isRequired,
      macAddressByte5: PropTypes.string.isRequired,
      macAddressByte6: PropTypes.string.isRequired,
      hostName: PropTypes.string.isRequired,
      ipV4Address: PropTypes.string.isRequired,
      state: PropTypes.bool.isRequired,
    })
  ).isRequired,
  onCopy: PropTypes.func.isRequired,
};

export default AvailableMacFilterTable;
