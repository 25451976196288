/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { Component, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import MetisMenu from 'metismenujs';
import SimpleBar from 'simplebar-react';

import sideBarLinks, { sideBarMessages } from './SideBarLinks';
import formattedMessageValues from '../i18n/FormattedMessageValues';

const SidebarContent = (props) => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const intl = useIntl();

  const handleModalClose = () => {
    setShowModal(false);
  };

  const toFirstPageInSubmenu = (firstItem) => {
    history.push(firstItem.link);
  };

  return (
    <div id="sidebar-menu">
      <ul className="metismenu list-unstyled" id="side-menu">
        {sideBarLinks.map((item, index) => {
          let classnames = 'waves-effect';
          if (index === 0) {
            classnames += ' first';
          }
          if (item.type === 'single') {
            return (
              <li key={item.intlMsgKey}>
                <Link to={item.link} className={classnames}>
                  <span>
                    {intl.formatMessage(sideBarMessages[item.intlMsgKey])}
                  </span>
                </Link>
              </li>
            );
          }
          if (item.type === 'submenu') {
            classnames += ' has-arrow';
            return (
              <li key={item.intlMsgKey}>
                <Link
                  to="/#"
                  className={classnames}
                  onClick={() => toFirstPageInSubmenu(item.values[0])}
                >
                  <span>
                    {intl.formatMessage(sideBarMessages[item.intlMsgKey])}
                  </span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {item.values.map((subitem) => {
                    return (
                      <li key={subitem.intlMsgKey}>
                        <Link to={subitem.link}>
                          {intl.formatMessage(
                            sideBarMessages[subitem.intlMsgKey]
                          )}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          }
          return false;
        })}
        {
          // link to setup wizard with modal dialogue
        }
        <li>
          <Modal
            show={showModal}
            onHide={handleModalClose}
            backdrop="static"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id="text.warning" defaultMessage="Warnung" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormattedMessage
                id="text.enter-setup-wizard-again"
                defaultMessage="Der O<sub>2</sub>{nbsp}Dienst ist bereits auf Ihrem Gerät eingerichtet. Wenn Sie Ihr O<sub>2</sub>{nbsp}Gerät neu einrichten wollen, z.B. nach einem Umzug, drücken Sie „Ausführen“. Der vorhandene DSL-und Telefoniezugang wird gelöscht!"
                values={formattedMessageValues}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                <FormattedMessage
                  id="button.cancel.label"
                  defaultMessage="Abbrechen"
                />
              </Button>
              <Link to="/assistant" className="waves-effect setup-assistant">
                <Button
                  variant="inactive"
                  onClick={() => {
                    handleModalClose();
                  }}
                >
                  <FormattedMessage
                    id="button.execute.label"
                    defaultMessage="Ausführen"
                  />
                </Button>
              </Link>
            </Modal.Footer>
          </Modal>
          <button
            type="button"
            className="waves-effect special-links"
            onClick={() => setShowModal(true)}
          >
            <span>
              {intl.formatMessage(sideBarMessages.sidebarlinksassistant)}
            </span>
          </button>
        </li>
        {
          // link to login page
        }
        <li>
          <Link to="/login" className="waves-effect special-links">
            <span>{intl.formatMessage(sideBarMessages.sidebarlinkslogin)}</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add('mm-active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active'); // li
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); // a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  };

  initMenu() {
    // eslint-disable-next-line no-new
    new MetisMenu('#side-menu');

    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu');
    const items = ul.getElementsByTagName('a');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < items.length; ++i) {
      // eslint-disable-next-line react/destructuring-assignment
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  render() {
    return (
      <>
        <SimpleBar style={{ maxHeight: '100%' }}>
          <SidebarContent />
        </SimpleBar>
      </>
    );
  }
}

export default withRouter(Sidebar);
