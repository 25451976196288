import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { SideBarWrapper } from './SideBarContainer.styles';
import SideBar from './SideBar';

function SideBarContainer({ isOpen, isMobile }) {
  return (
    <SideBarWrapper isOpen={isOpen} isMobile={isMobile}>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          <SideBar type="default" />
        </div>
      </div>
    </SideBarWrapper>
  );
}

export default withRouter(SideBarContainer);

SideBarContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
